(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.votacio')
    .controller('votaciotutorgradaCtrl', votaciotutorgradaCtrl);

  function votaciotutorgradaCtrl(resultat,perfil, $state,$scope,equip,valoraciones,items,partit, valoracions)
  {
     var vm=this;
    // vm.items=items.slice(0,items.length-1);
    vm.items=items;
    vm.show=false;
     vm.observacions=items[items.length-1].valor;
     if(resultat.length===1){
       var r={
       fecha:resultat[0].fecha,
       campionat:resultat[0].campionat,
        elocal:resultat[0].elocal,
    evisitant:resultat[0].evisitant,
    cc:[0].cc,
       pp:0,
       ptg:0,
       ptt:0,
       pte:0,
       pdt:0,
       pdp:0,
       marcador:0,
       verdplay:0,
       tv:0,
       puntsVisitant:0,totpunts:0,
       idEquipo:resultat[0].idVisitant,
       idPartido:resultat[0].idPartido,
       idLocal:resultat[0].idLocal,
       idVisitant:resultat[0].idVisitant
          };
       
       resultat.push(r);
   }
     if (vm.observacions===0){
         vm.observacions="";
     }
     vm.itempos=[];
     vm.valoracions=valoraciones;
    
     vm.perfil=perfil;
    
      $scope.opcions=[];
      $scope.valors=[];
 //     $scope.opcions[0]= [{ id: 1, name: "Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
 //     $scope.opcions[1]= [{ id: 1, name: "2Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//      $scope.opcions[2]= [{ id: 1, name: "3Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//      $scope.opcions[3]= [{ id: 1, name: "4Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//  $scope.opcions[4]= [{ id: 1, name: "5Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
////  $scope.opcions[5]= [{ id: 1, name: "6Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//  $scope.opcions[6]= [{ id: 1, name: "7Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
 $scope.item1={iditem:0,nom:"",respetoa:""};
 $scope.opcions1=[];
 var valorspos1=[];
 var valorscsv1=[];
 $scope.item2={iditem:0,nom:"",respetoa:""};
 $scope.opcions2=[];
 var valorspos2=[];
 var valorscsv2=[];
 $scope.item3={iditem:0,nom:"",respetoa:""};
 $scope.opcions3=[];
 var valorspos3=[];
 var valorscsv3=[];
 $scope.item13={iditem:0,nom:"",respetoa:""};
 $scope.opcions13=[];
 var valorspos13=[];
 var valorscsv13=[];
 $scope.item14={iditem:0,nom:"",respetoa:""};
 $scope.opcions14=[];
 var valorspos14=[];
 var valorscsv14=[];
 $scope.item15={iditem:0,nom:"",respetoa:""};
 $scope.opcions15=[];
 var valorspos15=[];
 var valorscsv15=[];
 $scope.item16={iditem:0,nom:"",respetoa:""};
 $scope.opcions16=[];
 var valorspos16=[];
 var valorscsv16=[];

    for(var j=0;j<vm.items.length;j++)
     {    
       
         if(vm.items[j].iditem===1){
             $scope.item1.iditem=1;
             $scope.item1.nom=vm.items[j].nom;
             $scope.item1.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions1[i]={id:i,name:a[i],selec:0};
             valorspos1[i]=valors[i];
             valorscsv1[i]=a[i];
         }
         }
         if(vm.items[j].iditem===2){
             $scope.item2.iditem=2;
             $scope.item2.nom=vm.items[j].nom;
             $scope.item2.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions2[i]={id:i,name:a[i],selec:0};
             valorspos2[i]=valors[i];
             valorscsv2[i]=a[i];
         }
         }
         if(vm.items[j].iditem===3){
             $scope.item3.iditem=3;
             $scope.item3.nom=vm.items[j].nom;
             $scope.item3.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions3[i]={id:i,name:a[i],selec:0};
             valorspos3[i]=valors[i];
             valorscsv3[i]=a[i];
         }
         }
        
        
      
       
         
         var a=vm.items[j].valorsCSV.split(";"); 
         var valors=vm.items[j].valoresposbiles.split(";");
         var c=[];
         var valorspos=[];
         var seleccionat=[];
         if ((vm.items[j].iditem===35)||(vm.items[j].iditem===36)||(vm.items[j].iditem===42)||(vm.items[j].iditem===43)){
             for(var i=0;i<100;i++){
             c[i]={id:i,name:i.toString(),selec:0};
             valorspos[i]=i.toString();
         }
         }else{
         for(var i=0;i<a.length;i++){
             c[i]={id:i,name:a[i],selec:0};
             valorspos[i]=valors[i];
         }}
    //$scope.opcions[j]([{ id: 1, name: "Gairebé mai"}, { id: 2, name:"Algunes vegades"}, { id: 3, name:"Sovint"}, { id: 4, name:"Gairebé sempre"}]);
 $scope.opcions[j]=c;
 $scope.valors[j]=valorspos;
 
 
      }
      $scope.seleccionat=[];
      var d=0;
       for (var f=0;f<vm.valoracions.length;f++)
              {
                 // for (var g=0;g<vm.items.length;g++)
                      
        //        if(vm.items[g].iditem===vm.valoracions[f].item) 
         //   {
            if(vm.valoracions[f].item===44){
               // alert(vm.valoracions[f].observacions);
                $scope.observ=vm.valoracions[f].observacions;}
      //      }else{
                   
                     if(vm.valoracions[f].item===1){
                         var i=valorspos1.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init1=$scope.opcions1[i].name;
                    }
                    
                     if(vm.valoracions[f].item===2){
                         var i=valorspos2.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init2=$scope.opcions2[i].name;
                    }
                    
                     if(vm.valoracions[f].item===3){
                         var i=valorspos3.indexOf(vm.valoracions[f].vporc.toString());
                         vm.init3=$scope.opcions3[i].name;
                    }
                      
                  
                  
                  
                      //  { //alert(g.toString()+"- idtiem:"+vm.valoracions[f].item+"-vproc:"+vm.valoracions[f].vporc.toString());
                        //      $scope.seleccionat[g]=$scope.valors[g].indexOf(vm.valoracions[f].vporc.toString());
                        //      d=$scope.opcions[g][$scope.seleccionat[g]].name;
                              //  alert($scope.valors[g][$scope.seleccionat[g]]+"cporc:"+vm.valoracions[f].vporc+"-index:"+ $scope.seleccionat[g]+$scope.opcions[g][$scope.seleccionat[g]].name+"selec:·"+$scope.opcions[g][$scope.seleccionat[g]].selec);
                               //$scope.seleccionat[g]=d;  
                     //          $scope.opcions[g][$scope.seleccionat[g]].selec=1;
                     //    }
                  
              }
       
     var  local=0;
   var visitant=1;
  
   
   
   if (resultat[0].idEquipo===resultat[0].idLocal){
       local=0;
       visitant=1;
   } else{
       local=1;
       visitant=0;
   }
    
    vm.campionat=resultat[local].campionat;
    vm.data=resultat[local].fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');;
    vm.elocal=resultat[local].elocal;
    vm.evisitant=resultat[local].evisitant;  
    vm.cc=resultat[local].cc;
    vm.ppl=resultat[local].pp;
    vm.ppv=resultat[visitant].pp;
     vm.ptgl=resultat[local].ptg;
    vm.ptgv=resultat[visitant].ptg;
     vm.pttl=resultat[local].ptt;
    vm.pttv=resultat[visitant].ptt;
     vm.ptel=resultat[local].pte;
    vm.ptev=resultat[visitant].pte;
      vm.pdtl=resultat[local].pdt;
    vm.pdtv=resultat[visitant].pdt;
      vm.pdpl=resultat[local].pdp;
    vm.pdpv=resultat[visitant].pdp;
     vm.marcadorl=resultat[local].marcador;
    vm.marcadorv=resultat[visitant].marcador;
    vm.pvpl=resultat[local].verdplay;
    vm.pvpv=resultat[visitant].verdplay;
     vm.tvl=resultat[local].tv;
    vm.tvv=resultat[visitant].tv;
    vm.pl=resultat[local].puntsLocal;
     vm.pv=resultat[visitant].puntsVisitant;
    if (resultat[local].suspendido){
        vm.suspes="PARTIT SUSPES!!";
    }else{
         vm.suspes=resultat[local].totpunts+ " - "+resultat[visitant].totpunts;
    }
  
  if (perfil==='1') $scope.perfilnom="Tutor/a de grada";
  if (perfil==='2') $scope.perfilnom="Públic";
  if (perfil==='4') $scope.perfilnom="Tècnic/a";
  if (perfil==='5') $scope.perfilnom="Tutor/a de joc";

  if (equip===resultat[0].idLocal.toString()){
      $scope.equipnom=vm.elocal;
  }else{
      $scope.equipnom=vm.evisitant;
  }
 vm.click=function (){
     var r=true;
      
               if(r===true)
        {
  if (vm.valoracions.length>2){
    for(var j=0;j<vm.valoracions.length;j++)
     {    
         var v={idvaloracion:0,idpartido:0,iditemvalorado:0,vporc:0,vmaximo:0,vvalor:0.00,idperfil:0,idusuario:0,idequipo:0,observaciones:'',idconsell:2};
    
         if (vm.valoracions[j].idvaloracion !== null){
             v.idvaloracion=vm.valoracions[j].idvaloracion;
            
             v.idpartido=vm.valoracions[j].idPartido;
             v.iditemvalorado=vm.valoracions[j].item;
             v.idperfil=1;
             v.idusuario=0;
             v.idequipo=vm.valoracions[j].idequipo;
             v.idconsell=2;
             
          
             
           
         
          if(vm.valoracions[j].item===1){
                  var i=valorscsv1.indexOf($scope.select1);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*8.25;
                     v.observaciones="";
                     v.vmaximo=33;
                    
                    }
           if(vm.valoracions[j].item===2){
                  var i=valorscsv2.indexOf($scope.select2);
                 
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*8.25;
                     v.observaciones="";
                     v.vmaximo=33;
                 
                    }
                    
             if(vm.valoracions[j].item===3){
                  var i=valorscsv3.indexOf($scope.select3);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*8.25;
                     v.observaciones="";
                     v.vmaximo=34;
              
                    }
          
                
            
           var  fecha=new Date();
        //   v.fecha_hora=fecha.getFullYear()+"-"+(fecha.getMonth() + 1) + "-" + fecha.getDate() + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
        
             valoracions.update({idpartit: v.idpartido,idequip:v.idequipo,idperfil:v.idperfil}, v);
            } 
              }
              //alert("voy");
          var url = $state.href("home.resultats",{idPartit: v.idpartido});
           window.open(url,'_self');
          }
          else{
              for(var j=0;j<vm.items.length;j++)
     {    
            var v={idpartido:0,iditemvalorado:0,vporc:0,vmaximo:0,vvalor:0.00,idperfil:0,idusuario:0,idequipo:0,observaciones:'',idconsell:2};
             
             v.idpartido=resultat[local].idPartido;
             v.iditemvalorado=vm.items[j].iditem;
             v.idperfil=1;
             v.idusuario=0;
             //alert(vm.items[j].iditem);
             v.idconsell=2;
             v.idequipo=equip;
             
            
         
        
           if(vm.items[j].iditem===1){
                  var i=valorscsv1.indexOf($scope.select1);
                 
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*8.25;
                     v.observaciones="";
                     v.vmaximo=33;
                      
                 
                    }
                    
             if(vm.items[j].iditem===2){
                  var i=valorscsv2.indexOf($scope.select2);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*8.25;
                     v.observaciones="";
                     v.vmaximo=33;
                     
              
                    }
           if(vm.items[j].iditem===3){
                  var i=valorscsv3.indexOf($scope.select3);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*8.25;
                     v.observaciones="";
                     v.vmaximo=34;
                
                    }
                    
        
            
            
          
                    
           
          
           var  fecha=new Date();
        //   v.fecha_hora=fecha.getFullYear()+"-"+(fecha.getMonth() + 1) + "-" + fecha.getDate() + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
        
             valoracions.insert({idpartit: v.idpartido,idequip:v.idequipo,idperfil:v.idperfil}, v);
             
              } 
              
          var url = $state.href("home.resultats",{idPartit: v.idpartido});
           window.open(url,'_self');
          }
          
          
          
          
  }
 };  
 }}());